import React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";

import logo from "/src/images/iso_color.png";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Warranty = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Impressum | Custom Surgical" />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1200px",
          padding: "20px",
          paddingTop: "4em",
          marginBottom: "2em",
        }}
      >
        <h1 style={{ marginBottom: "5%" }}>Impressum</h1>
        <div
          className="container3"
          id="footer_page_flex"
          style={{ justifyContent: "space-between" }}
        >
          <div id="footer_page_width1">
            <h1 style={{ marginTop: "0" }}>Custom Surgical GmbH</h1>
            <p>
              <b>Address: </b>Custom Surgical GmbH, Agnes-Pockels-Bogen 1, 80992
              Munich, Germany
            </p>
            <p>
              Tel :{" "}
              <a href="tel:++49 152 346 89005" className="links_post">
                +49 152 346 89005
              </a>
            </p>
            <p>
              Email:{" "}
              <a className="links_post" href="mailto:info@customsurgical.de">
                info@customsurgical.de
              </a>
            </p>
          </div>
          <div id="footer_page_width2">
            <p>
              CEO/Authorized representative:<br></br>
              Federico Acosta
            </p>
            <p>
              Court of registry:<br></br>
              Local court of Munich
            </p>
            <p>
              Company number:<br></br>
              HRB 251147
            </p>
            <p>
              VAT Registration Number:<br></br>
              DE327157286
            </p>
            <p>
              Responsible for content according to no.55 Abs.2 RtsV 3 of the
              German Interstate Media Services Treaty:<br></br>
              Federico Acosta
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Warranty;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
